<template>
  <div>
    <template v-if="config.controls">
      <div class="btnbox">
        <div v-if="continueBtn" class="text-shadow" @click="play();continueBtn=null">继续</div>
        <div v-if="endBtn && continueBtn==null" class="text-shadow" @click="toEnd();endBtn=null">跳过</div>
      </div>
      <div class="" style="display:flex;padding:0.5em;font-size:12px;position:relative">
        <a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" v-if="state!='play'" @click="play"><i class="fas fa-play"></i></a>
        <a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" v-else @click="pause"><i class="fas fa-pause"></i></a>
        <span class="text-shadow" style="flex:none">{{ moment(nowTime).format('mm:ss')}}</span>
        <input type="range" class="" min="0" :max="totalDuration" v-model="autoTourPlayer().currentTime" style="flex: auto; margin: 0 5px;" />
        <span class="text-shadow" style="flex:none">{{moment(totalDuration).format('mm:ss')}}</span>
        <!--<a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" @click="stop"><i class="fas fa-stop"></i></a>-->
        <!--<div style="background-color:wheat">{{nowTime}}/{{nowDate}}/{{state}}</div>-->
      </div>
    </template>
    <div class="subtitleP">
      <div class="subtitle">
        <transition-group name="subtitle">
          iv
          <div v-for="s in subtitles" v-bind:key="s.key">
            <div class="subtitle-item">
              {{ s.text }}
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <div class="videos">
      <div v-for="i in videos" :key="i.key" :style="getimgstyle(i)"><canvas ref="videoCanvas"></canvas></div>
    </div>
    <div class="images">
      <div v-for="i in images" :key="i.key" :style="getimgstyle(i)"></div>
    </div>
    <div class="texts">
      <div v-for="i in texts" :key="i.key" :style="getTextstyle(i)"><div v-html="i.text"></div></div>
    </div>
    <div class="massgae" v-if="massageData!=null" @click="massageCancel">
      <template v-if="typeof massageData=='string'">
        <div class="textBox">{{massageData}}</div>
      </template>
    </div>
    <!--<template v-for="s in subtitles">
      <div class="subtitleP">
        <div class="subtitle" :class="{show:s.show}">{{s.text}}</div>
      </div>
    </template>-->

  </div>
</template>
<script>
  import uuid from 'uuid'
  import moment from 'moment'
  //import keyFrames from '../../../libs/keyFrames'
  import {
    SetView,
    PlayAudio,
    SetHotspot,
    Subtitle,
    ViewImage,
    ViewVideo,
    ViewText,
    PausePoint,
  } from '../../../libs/autoTourActs'

  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        nowDate: null,
        nowTime: 0,
        actNowIndex: 0,
        state: 'stop',
        playTime: null,
        playTimeLine: null,
        plan: null,
        laterUpdateTime: null,
        laterUpdateTimer: null,
        runingAct: [],
        autoTour: {},
        renewTimeP: null,
        moment: moment,
        waitPlay: false,
        subtitles: [],
        images: [],
        videos: [],
        texts: [],
        videoPlayer: null,
        loadingList: {},
        loading: false,
        massageData: null,
        continueBtn: null,
        massageCallback: null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    watch: {
      nowTime(val) {
        this.$emit('timeChange', val)
      },
    },
    computed: {
      totalDuration() {
        if (!this.autoTour) {
          return 0
        }
        var t = 0
        for (var i in this.autoTour.acts) {
          var a = this.autoTour.acts[i]
          if (a.timeline > t) {
            t = +a.timeline
          }
          if (a.duration && +a.timeline + +a.duration > t) {
            t = +a.timeline + +a.duration
          }
        }
        return t
      },
      tour() {
        console.log('this.publicData.tour', this.publicData.tour)
        return this.publicData.tour || {}
      },
      endBtn() {
        return this.config.endBtn
      }
      //  autoTour() {
      //    return tour.autoTour || {}
      //  }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          play: this.play,
          pause: this.pause,
          stop: this.stop,
          toggle: this.toggle,
          fileEnd: this.fileEnd,
          setAutoTour: this.setAutoTour,
          panoLoadCompleted: this.panoLoadCompleted,
          videoEnable: this.videoEnable,
          videoDisable: this.videoDisable,
        },
        events: {
          audioPlay: {
          },
        }
      })
      this.autoTour = this.tour.autoTour || {}
      this.getFunc('changePublicData')({
        autoTourPlayer: this.autoTourPlayer(),
        panoInfo: { cleanState: true }
      })

      if (this.config.autoPlay) {
        if (this.getFunc({ target: 'FullPano', name: 'getLoadCompleted' })()) {
          this.play()
        } else {
          this.waitPlay = true
        }
      }

      if (this.getFunc({ target: 'FullPano', name: 'isPanoVideo' })()) {
        this.videoEnable()
      }
    },
    destroyed() {
      console.log('autotour destroyed')
      this.getFunc('changePublicData')({
        panoInfo: { cleanState: false }
      })
      this.stop()
      this.cleanActs()
    },
    methods: {
      toggle() {
        switch (this.state) {
          case 'play':
            this.pause()
            break
          default:
            this.play()
        }
      },
      toEnd() {
        this.setTime(this.totalDuration)
        this.$emit('end')
        this.state = 'end'
      },
      sendMassage(msg, callback) {
        this.massageData = msg
        this.massageCallback = callback
      },
      massageCancel() {
        if (typeof this.massageData == 'string') {
          this.massageData = null
        }
        if (this.massageCallback) {
          this.massageCallback('Cancel')
        }
      },
      setActsTime(time) {
        console.log('setActsTime', time)
        this.cleanActs()
        this.clearRenewTime()
        var acts = this.getRunningActs(time)
        var hasView = false
        for (var i in acts) {
          if (acts[i].act == 'setView') {
            hasView = true
          } else {
            var a = this.creatAct(acts[i], time)
            console.log('add runingAct', a, acts[i])
            this.runingAct.push(a)
          }
        }
        if (hasView) {
          var act = this.getLastViewAct(time)
          if (act) {
            var a = this.creatAct(act, time)
            this.runingAct.push(a)
          }
        }
        if (this.autoTour.acts.length > 0) {
          if (time < this.autoTour.acts[0].timeline) {
            this.actNowIndex = -1
          } else {
            for (var i in this.autoTour.acts) {
              if (this.autoTour.acts[i].timeline <= time) {
                this.actNowIndex = Number(i)
              }
            }
          }
        } else {
          this.actNowIndex = -1
        }
        this.nowTime = Number(time)
        this.$forceUpdate()
      },
      playActs() {
        if (this.plan) {
          clearTimeout(this.plan)
          this.plan = null
        }
        this.playTime = new Date()
        this.playTimeLine = this.nowTime
        var time = (new Date().getTime() - this.playTime.getTime()) + this.playTimeLine
        for (var i in this.runingAct) {
          var a = this.runingAct[i]
          if (a.play) {
            a.play(time)
          }
        }
        this.nextActs()
        this.nowDate = new Date()
        this.startRenewTime()
      },
      nextActs() {
        var next = this.getNextAct()
        var pt = this.playTime
        var time = (new Date().getTime() - pt.getTime()) + this.playTimeLine
        while (next && time > next.timeline) {
          var a = this.creatAct(next, time)
          this.runingAct.push(a)
          a.play()
          this.actNowIndex++
          next = this.getNextAct()
          time = (new Date().getTime() - pt.getTime()) + this.playTimeLine
        }
        if (next) {
          this.plan = setTimeout(this.nextActs, next.timeline - time)
        } else {
          this.plan = setTimeout(this.endActs, this.totalDuration - time)
        }
        this.$forceUpdate()
      },
      endActs() {
        this.playTime = null
        this.state = 'end'
        this.$emit('end')
        this.clearRenewTime()
      },
      stopActs() {
        if (this.playTime) {
          this.nowTime = this.playTimeLine + (new Date().getTime() - this.playTime.getTime())
          this.playTime = null
        }
        if (this.plan) {
          clearTimeout(this.plan)
          this.plan = null
        }
        for (var i in this.runingAct) {
          var a = this.runingAct[i]
          a.pause()
        }
        this.clearRenewTime()
      },
      cleanActs() {
        while (this.runingAct.length > 0) {
          this.runingAct[0].destroy()
        }
      },
      getSenceData(guid) {
        for (var i in this.tour.items) {
          if (this.tour.items[i].guid == guid)
            return this.tour.items[i]
        }
      },
      getVideoPlayer() {
        return this.getFunc({ target: 'FullPano', name: 'getVideoPlayer' })()
      },
      addLoading(type, callback) {
        this.toLoading()
        if (!this.loadingList[type]) {
          this.loadingList[type] = []
        }
        var complate = (...arg) => {
          if (callback) {
            callback(...arg)
          }
          var i = this.loadingList[type].indexOf(complate)
          if (i != -1) {
            this.loadingList[type].splice(i, 1)
          }
          console.log('addLoading complate')
          var enpty = true
          for (var i in this.loadingList) {
            if (this.loadingList[i] && this.loadingList[i].length > 0) {
              enpty = false
            }
          }
          if (enpty) {
            this.toloaded()
          }
        }
        this.loadingList[type].push(complate)
        console.log('addLoading')
        return complate
      },
      complateLoading(type, ...args) {
        console.log(type, 'complateLoading')
        if (this.loadingList[type]) {
          for (var i in this.loadingList[type]) {
            this.loadingList[type][i](...args)
          }
        }
      },
      toLoading() {
        if (!this.loading) {
          this.loading = true
          if (!this.autoTour) {
            return
          }
          if (!(this.state == 'play')) {
            return
          }
          this.stopActs()
          this.$emit('loading')
        }
      },
      toloaded() {
        console.log('toloaded')
        if (this.loading) {
          this.loading = false
          switch (this.state) {
            case 'play':
              this.nowDate = new Date()
              this.getFunc('changePublicData')({
                panoInfo: { cleanState: true }
              })
              this.playActs()
              break
            case 'pause':
            case 'stop':
            case 'end':
              break
            default:
          }
          this.$emit('loaded')
          this.$forceUpdate()
        }
      },
      videoEnable() {
        this.videoPlayer = this.getFunc({ target: 'FullPano', name: 'getVideoPlayer' })()
        console.log('videoEnable', this.videoPlayer)
        this.complateLoading('videoEnable', this.videoPlayer)
      },
      videoDisable() {
        this.complateLoading('videoDisable')
        this.videoPlayer = null
      },
      getimgstyle(i) {
        var style = {
          position: 'absolute',
          left: '50vw',
          top: '50vh',
          width: '80vw',
          height: '80vh',
          'background-image': `url(${i.imgUrl})`,
          'background-repeat': `no-repeat`,
          'background-size': `contain`,
          'background-position': `center`,
        }
        var rect = i.rect
        if (rect) {
          var w = rect.width
          var h = rect.height
          style.left = `${50 - rect.x - (w / 2)}vw`
          style.top = `${50 - rect.y - (h / 2)}vh`
          style.width = `${w}vw`
          style.height = `${h}vh`
        }
        if (i.transition) {
          style.transition = i.transition
        }
        if (i.opacity != null) {
          style.opacity = i.opacity
        }
        if (i.rotate) {
          style.transform = `rotateX(${i.rotate.x || 0}deg) rotateY(${i.rotate.y || 0}deg) rotateZ(${i.rotate.z || 0}deg) `
        }
        if (i.scale != null) {
          if (style.transform) {
            style.transform = style.transform + ` scale(${i.scale})`
          } else {
            style.transform = `scale(${i.scale})`
          }
        }
        if (i.bgMode) {
          style['background-position'] = i.bgMode.position
          style['background-size'] = i.bgMode.size
          style['background-repeat'] = i.bgMode.repeat
        }
        if (i.blendMode) {
          style['mix-blend-mode'] = i.blendMode
        }
        return style
      },
      getTextstyle(i) {
        var style = {
          position: 'absolute',
          left: '50vw',
          top: '50vh',
          width: '80vw',
          height: '80vh',
          //  overflow: 'hidden',
          display: 'flex',
          'justify-content': 'center',
          'align-items': 'center',
        }
        var rect = i.rect
        if (rect) {
          var w = rect.width
          var h = rect.height
          style.left = `${50 - rect.x - (w / 2)}vw`
          style.top = `${50 - rect.y - (h / 2)}vh`
          style.width = `${w}vw`
          style.height = `${h}vh`
        }
        if (i.transition) {
          style.transition = i.transition
        }
        if (i.opacity != null) {
          style.opacity = i.opacity
        }
        if (i.fontColor) {
          style.color = i.fontColor
        }
        if (i.fontSize != null) {
          style['font-size'] = `${i.fontSize}px`
        }
        if (i.lineHeight != null) {
          style['line-height'] = `${i.lineHeight}`
        }
        if (i.bgColor) {
          style['background-color'] = i.bgColor
        }
        if (i.rotate) {
          style.transform = `rotateX(${i.rotate.x || 0}deg) rotateY(${i.rotate.y || 0}deg) rotateZ(${i.rotate.z || 0}deg) `
        }
        if (i.scale != null) {
          if (style.transform) {
            style.transform = style.transform + ` scale(${i.scale})`
          } else {
            style.transform = `scale(${i.scale})`
          }
        }
        if (i.testShadows) {
          var tsstr = ''
          for (var tsi = 0; tsi < i.testShadows.length; tsi++) {
            var s = i.testShadows[tsi]
            tsstr = tsstr + `${s.x}px ${s.y}px ${s.blurRadius}px ${s.color}`
            if (tsi + 1 < i.testShadows.length) {
              tsstr = tsstr + ','
            }
          }
          style['text-shadow'] = tsstr
        }
        if (i.border) {
          style.border = `${i.border.width}px ${i.border.style} ${i.border.color}`
        }
        if (i.textAlign) {
          style['text-align'] = i.textAlign
        }
        if (i.blendMode) {
          style['mix-blend-mode'] = i.blendMode
        }
        if (i.edge) {
          style.display = 'flex'
          style['justify-content'] = 'center'
          style['align-items'] = 'center'
          switch (i.edge) {
            case 'lefttop':
              style['justify-content'] = 'left'
              style['align-items'] = 'flex-start'
              break
            case 'top':
              style['justify-content'] = 'center'
              style['align-items'] = 'flex-start'
              break
            case 'righttop':
              style['justify-content'] = 'right'
              style['align-items'] = 'flex-start'
              break
            case 'left':
              style['justify-content'] = 'left'
              style['align-items'] = 'center'
              break
            case 'center':
              style['justify-content'] = 'center'
              style['align-items'] = 'center'
              break
            case 'right':
              style['justify-content'] = 'right'
              style['align-items'] = 'center'
              break
            case 'leftbottom':
              style['justify-content'] = 'left'
              style['align-items'] = 'flex-end'
              break
            case 'bottom':
              style['justify-content'] = 'center'
              style['align-items'] = 'flex-end'
              break
            case 'rightbottom':
              style['justify-content'] = 'right'
              style['align-items'] = 'flex-end'
              break
            default:
          }
        }
        console.log('getTextstyle', style)
        return style
      },
      panoLoadCompleted() {
        console.log('panoLoadCompleted')
        this.complateLoading('panoLoad')
        if (this.waitPlay) {
          this.play()
          this.waitPlay = false
        }
      },
      autoTourPlayer() {
        var _this = this
        return {
          play: this.play,
          stop: this.stop,
          pause: this.pause,
          onTimeChange(func) {
            _this.$on('timeChange', func)
          },
          setTime: this.setTime,
          offTimeChange(func) {
            _this.$off('timeChange', func)
          },
          renew() {
            _this.setTime(_this.nowTime || 0)
          },
          get totalDuration() {
            return _this.totalDuration
          },
          get state() {
            return _this.state
          },
          get runningActs() {
            if (_this.state == 'stop' || _this.state == 'end') {
              return []
            }
            return []
            //  return _this.getRunningActs()
          },
          get currentTime() {
            return _this.nowTime || 0
          },
          set currentTime(val) {
            if (_this.state == 'play') {
              _this.pause()
            }
            _this.setTime(Number(val))
          },
          get currentIndex() {
            return _this.actNowIndex
          },
          set currentIndex(val) {
            var act = _this.autoTour.acts[val]
            if (act) {
              _this.setTime(act.timeline)
            }
          },
          get nowActons() {
            return _this.getRunningActs()
          },
          get continueBtn() {
            return _this.continueBtn
          },
          set continueBtn(val) {
            _this.continueBtn = val
          }
        }
      },
      getRunningActs(t) {
        var time = Number(t)
        if (isNaN(time)) {
          time = this.nowTime
        }
        var acts = []
        for (var i in this.autoTour.acts) {
          let a = this.autoTour.acts[i]
          if (+a.timeline <= time && +a.timeline + +a.duration > time) {
            acts.push(a)
          }
        }
        return acts
      },
      getActIndex(act) {
        for (var i in this.autoTour.acts) {
          if (this.autoTour.acts[i] == act) {
            return i
          }
        }
      },
      startRenewTime() {
        if (this.renewTimeP) {
          this.clearRenewTime()
        }
        this.renewTime()
        this.renewTimeP = setInterval(this.renewTime, 50)
      },
      clearRenewTime() {
        this.renewTime()
        clearInterval(this.renewTimeP)
        this.renewTimeP = null
      },
      renewTime() {
        if (this.nowDate) {
          this.nowTime = this.nowTime + new Date().getTime() - this.nowDate.getTime()
          this.nowDate = new Date()
        }
      },
      getLastViewAct(time) {
        var nowTime = Number(time) || this.nowTime
        var act = null
        for (var i in this.autoTour.acts) {
          let a = this.autoTour.acts[i]
          if (a.act == 'setView' && +a.timeline <= nowTime) {
            act = a
          }
        }
        return act
      },
      setAutoTour() {
        this.autoTour = this.tour.autoTour
      },
      nowSenceGuid() {
        return this.publicData.senceGuid || this.publicData.editItemGuid
      },
      setPano(guid, query) {
        console.log('setPano', guid, query)
        this.getFunc({ target: 'Data', name: 'toPano' })(guid, query)
      },
      pauseSetView(data) {
        var view = data.view
        this.getFunc({
          target: 'FullPano',
          name: 'stopTweenView'
        })({ ...view })
      },
      play() {
        if (!this.autoTour) {
          return
        }
        if (this.loading) {
          console.log('loading play')
          this.state = 'play'
          return
        }
        switch (this.state) {
          case 'stop':
          case 'end':
            this.getFunc('changePublicData')({
              panoInfo: { cleanState: true }
            })
            this.setActsTime(0)
          case 'pause':
            this.state = 'play'
            this.$emit('play')
            this.playActs()
            if (this.continueBtn) {
              this.continueBtn = null
            }
            break
          default:
        }
      },
      creatAct(act, time) {
        var a
        switch (act.act) {
          case 'setView':
            a = new SetView(this, act, time)
            break
          case 'playAudio':
            a = new PlayAudio(this, act, time)
            break
          case 'setHotspot':
            a = new SetHotspot(this, act, time)
            break
          case 'subtitle':
            a = new Subtitle(this, act, time)
            break
          case 'viewImage':
            a = new ViewImage(this, act, time)
            break
          case 'viewVideo':
            a = new ViewVideo(this, act, time)
            break
          case 'viewText':
            a = new ViewText(this, act, time)
            break
          case 'pausePoint':
            a = new PausePoint(this, act, time)
            break
          default:
            console.error(`not find ${act.act}`)
            return null
        }
        return a
      },
      removeRunnningAct(a) {
        console.log('removeRunnningAct', a)
        this.runingAct.splice(this.runingAct.indexOf(a), 1)
      },
      pause() {
        if (!this.autoTour) {
          return
        }
        if (!(this.state == 'play')) {
          return
        }
        this.stopActs()
        this.state = 'pause'
        this.$emit('pause')
      },
      continue() {
        if (!this.autoTour) {
          return
        }
        if (!(this.state == 'pause')) {
          return
        }
        this.nowDate = new Date()
        this.playActs()
        this.state = 'play'
      },
      stop() {
        if (!this.autoTour) {
          return
        }
        this.stopActs()
        this.nowTime = 0
        this.getFunc('changePublicData')({
          panoInfo: { cleanState: false }
        })
        this.state = 'stop'
        this.$emit('stop')
      },
      setTime(time) {
        if (this.laterUpdateTimer) {
          this.laterUpdateTime = time
          return
        }
        console.log('setTime', time)
        this.setActsTime(time)
        this.laterUpdateTimer = setTimeout(() => {
          this.laterUpdateTimer = null
          if (this.laterUpdateTime != null) {
            this.setActsTime(this.laterUpdateTime)
            this.laterUpdateTime = null
          }
        }, 100)
      },
      getNextAct() {
        return this.autoTour.acts[this.actNowIndex + 1]
      },
    },
  }
</script>
<style scoped>
  .videos {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
  }

    .videos > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .videos canvas {
      max-width: 100%;
      max-height: 100%;
    }

  .images {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
  }

  .texts {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
  }

  .massgae {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .massgae .textBox {
      background-color: #0006;
      border: 1px solid #000;
      color: #fff;
      max-width: 80vw;
      max-height: 80vh;
      padding: 5px;
    }

  .btnbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .btnbox > * {
      margin: 0 5px;
    }

  .subtitleP {
    position: fixed;
    top: 61.8vh;
    left: 0;
    right: 0;
    /*bottom: 200px;*/
    pointer-events: none;
  }

    .subtitleP .subtitle {
      /*pointer-events:auto;*/
      /*display: flex;*/
      /*justify-content: center;*/
      /*align-items: center;*/
      text-align: center
    }

  .subtitle-item {
    margin: auto;
    display: inline-block;
    background-color: #0006;
    color: white;
    font-size: 18px;
    padding: 5px;
    max-width: 85vw;
    min-height: 35px;
    /*margin-bottom: 5px;*/
  }

  /*.subtitle-enter-active, .subtitle-leave-active, .subtitle-move {
    transition: all 0.5s;
  }

  .subtitle-enter {
    opacity: 0;
    transform: translateY(30px);
  }

  .subtitle-leave-to*/
  /* .list-leave-active for below version 2.1.8 */ /*{
    opacity: 0;
    transform: translateY(-10px);
  }*/
  .subtitle-enter-active {
    animation: subtitle-in-down 1s;
  }

  .subtitle-leave-active {
    animation: subtitle-out 0.5s;
  }

  @keyframes subtitle-in-down {
    0% {
      opacity: 0;
      /*transform: translateY(-20px);*/
    }

    100% {
      opacity: 1;
      /*transform: translateY(0px);*/
    }
  }

  @keyframes subtitle-out {
    0% {
      opacity: 1;
      margin-top: 0;
    }

    100% {
      opacity: 0;
      margin-top: -35px;
    }
  }
</style>
<style>
  .texts p {
    text-align: unset;
    line-height: unset;
    letter-spacing: unset;
    text-indent: unset;
  }
</style>
